
<div mat-dialog-title style="float: right; font-weight: 700; cursor: pointer;margin: 0px;"  (click)="close()">X</div>
<div style="width: 100%;float:left;">
<h1>Are you sure?</h1>
<mat-dialog-content>
    <p>You have  unsaved data, Do you want to save changes?</p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button  [mat-dialog-close]="true">No</button>
    <button mat-button mat-dialog-close>Yes</button>
</mat-dialog-actions>
</div>
